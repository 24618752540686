var $ = require("jquery");
import Swiper from "swiper/bundle";

export const pladsSlider = () => {
  const slider = new Swiper(".js-plads-slider", {
    slidesPerView: 1,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    allowTouchMove: false,
  });
};
