import $ from "jquery";

export const randomVideo = () => {
  const videoSource = $(".js-video-random");

  const randomInt = Math.floor(Math.random() * 5) + 1;

  videoSource.attr(
    "src",
    `https://hygge-plads.com/wp-content/themes/hygge-corp-theme/assets/img/common/hygge-${randomInt}.mp4`
  );
};
