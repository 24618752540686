var $ = require("jquery");

import { loading } from "./modules/loading";
import { inview } from "./modules/inview";
import { anchorSmoothScroll } from "./modules/anchorSmoothScroll";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { treatmentToggle } from "./modules/treatmentToggle";
import { randomVideo } from "./modules/randomVideo";
import { pladsSlider } from "./modules/pladsSlider";

$(function () {
  loading();
  inview();
  anchorSmoothScroll();
  hamburgerMenu();
  treatmentToggle();
  randomVideo();
  pladsSlider();
});
