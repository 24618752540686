var $ = require("jquery");

export const treatmentToggle = () => {
  const btns = $(".js-treatment-toggle-btn");

  btns.on("click", function () {
    const content = $(this).prev(".js-treatment-toggle-text");
    content.slideToggle();

    const btnText = $(this).find(".text");

    $(this).toggleClass("is-open");
    if (btnText.text() === "more") {
      btnText.replaceWith('<span class="text">show less</span>');
    } else {
      btnText.replaceWith('<span class="text">more</span>');
    }
  });
};
