import { mvAnimation } from "./mvAnimation";

var $ = require("jquery");

export const loading = () => {
  if ($(".js-loading-animation").length !== 0) {
    const loading = $("#loading");

    $("body").addClass("is-loading");

    setTimeout(() => {
      $("body").removeClass("is-loading");
      loading.fadeOut(1000);

      mvAnimation();
    }, 3000);
  }
};
